@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* SERIF : Apoc Revelations */
  @font-face {
    font-family: "Apoc Revelations";
    font-style: normal;
    font-weight: 300; /*light*/
    font-display: swap;
    src: url("./fonts/ApocRevelations-Light.woff2") format("woff2"),
      url("./fonts/ApocRevelations-Light.woff") format("woff");
  }

  @font-face {
    font-family: "Apoc Revelations";
    font-style: normal;
    font-weight: 700; /*bold*/
    font-display: swap;
    src: url("./fonts/ApocRevelations-Bold.woff2") format("woff2"),
      url("./fonts/ApocRevelations-Bold.woff") format("woff");
  }

  @font-face {
    font-family: "Apoc Revelations";
    font-style: italic;
    font-weight: 300; /*light*/
    font-display: swap;
    src: url("./fonts/ApocRevelations-LightItalic.woff2") format("woff2"),
      url("./fonts/ApocRevelations-LightItalic.woff") format("woff");
  }

  @font-face {
    font-family: "Apoc Revelations";
    font-style: italic;
    font-weight: 700; /*bold*/
    font-display: swap;
    src: url("./fonts/ApocRevelations-BoldItalic.woff2") format("woff2"),
      url("./fonts/ApocRevelations-BoldItalic.woff") format("woff");
  }

  /* SANS : Area Normal */
  @font-face {
    font-family: "Area Normal";
    font-style: normal;
    font-weight: 400; /*regular*/
    font-display: swap;
    src: url("./fonts/AreaNormal-Regular.woff2") format("woff2"),
      url("./fonts/AreaNormal-Regular.woff") format("woff");
  }

  @font-face {
    font-family: "Area Normal";
    font-style: italic;
    font-weight: 400; /*regular*/
    font-display: swap;
    src: url("./fonts/AreaNormal-RegularItalic.woff2") format("woff2"),
      url("./fonts/AreaNormal-RegularItalic.woff") format("woff");
  }

  /* MONOSPACE : FT88 */
  @font-face {
    font-family: "FT88";
    font-style: normal;
    font-weight: 400; /*regular*/
    font-display: swap;
    src: url("./fonts/FT88-Regular.woff2") format("woff2");
  }

  h1,
  h2,
  h3 {
    font-family: "Apoc Revelations", serif;
  }

  p {
    font-family: "Area Normal", sans-serif;
  }

  code {
    font-family: "FT88", monospace;
  }
}
