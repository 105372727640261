.leftPanel {
  transition: height 0.3s ease-in;
  z-index: 11;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 0rem;
  background: rgb(255,207,207);
  background: linear-gradient(270deg, rgba(255,207,207,1) 0%, rgba(217,199,220,1) 65%, rgba(255,180,252,1) 100%);
}

.rightPanel {
  transition: width 0.4s ease-in;
  z-index: 11;
  position: fixed;
  right: 0;
  width: 0vw;
  height: 100vh;
  background: rgb(207, 207, 207);
  background: linear-gradient(
    180deg,
    rgba(207, 207, 207, 1) 14%,
    rgba(217, 199, 220, 1) 58%,
    rgba(240, 180, 252, 1) 100%
  );
}

.rightPanel.w100 {
  width: 100vw;
}

.leftPanel.w50 {
  height: 36rem;
}

.rightPanel.w50 {
  width: 50rem;
}

.transformRotatePos {
  transform: none;
}


/* MEDIA QUERIES */


/* for Mobile HORIZONTAL 480px and above */
@media all and (min-width: 480px) {
  .transformRotatePos {
    /* Safari */
    -webkit-transform: rotate(-90deg) translate(2.8rem, -3.8rem);
  
    /* Firefox */
    -moz-transform: rotate(-90deg);
  
    /* IE */
    -ms-transform: rotate(-90deg);
  
    /* Opera */
    -o-transform: rotate(-90deg);
  
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }

  .leftPanel {
    transition: width 0.3s ease-in;
    width: 0rem;
    height: 100vh;
    background: rgb(207, 207, 207);
    background: linear-gradient(
      180deg,
      rgba(207, 207, 207, 1) 14%,
      rgba(195, 211, 218, 1) 55%,
      rgba(163, 220, 245, 1) 100%
    );
  }

  .leftPanel.w50 {
    width: 35rem;
    height: 100vh;
  }

  .rightPanel.w50 {
    width: 35rem;
  }
}

/* for SMALL SCREEN 1280px and above */
@media all and (min-width: 1280px) {

  .rightPanel.w50 {
    width: 50rem;
  }
}

/* for WIDESCREEN 1800px and above */
@media all and (min-width: 1800px) {
  .leftPanel.w50 {
    width: 60rem;
  }

  .rightPanel.w50 {
    width: 80rem;
  }
}
