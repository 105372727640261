.slide-right-to-left{
    /* left:0; */
    /* right:-100%; */
    -webkit-animation: right-to-left 0.2s linear forwards;
    animation: right-to-left 0.2s linear forwards;
}
@-webkit-keyframes right-to-left{
    from{right:-100%}
    to{right:0}
}
@keyframes right-to-left{ 
    from{right:-100%}
    to{right:0}
}
.slide-left-to-right{
    /* left:-100%; */
    /* right:0; */
    -webkit-animation: left-to-right 0.2s linear forwards;
    animation: left-to-right 0.2s linear forwards;
}
@-webkit-keyframes left-to-right{
    from{left:0}
    to{left:-200%}
}
@keyframes left-to-right{ 
    from{left:0}
    to{left:-200%}
}
.opacity-fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s linear;
}
.opacity-fade-in{
    visibility: visible;
    /* opacity: 1;
    transition: visibility 0s 0.3s, opacity 0.3s linear; */
}


.buttonInactive {
    display: flex;
    transition: all 0.2s ease-in;
    /* background-color: white; */
    /* box-shadow: 0px 0px 4px 4px #FFF; */
    border-radius: 1.8rem;
    margin: 1rem 1rem;
    padding: 0rem 0.8rem;
}

.buttonInactive.about {
    width: 27rem;
}

.buttonInactive.white {
    background-color: white;
    box-shadow: 0px 0px 4px 4px #FFF;
    /* width: 100vw; */
}

.buttonActive {
    display: flex;
    transition: all 0.2s ease-in;
    background-color: black;
    box-shadow: 0px 0px 4px 4px #000;
    color: white;
    border-radius: 1.8rem;
    margin: 1rem 1rem;
    padding: 0rem 0.8rem;
}

/* .button {
    transition: box-shadow 0.2s ease-in, border-color 0.2s ease-in;
}

.button:hover {
    box-shadow: inset 0px 0px 4px 4px rgba(0,0,0,0.1);
    border-color: rgba(0,0,0,0.3);
}

.button:active {
    background-color: black;
    color: white;
} */

.gradientOff {
    opacity: 0 !important;
}

.animateOpacity {
    visibility: hidden;
    transition: opacity .1s ease-in-out;
    opacity: 0;
}

.animateOpacity.hide {
    height: 0px !important;
    width: 0px !important;
}

.introWindow {
    transition: opacity .5s ease-out;
}

.animateOpacity.show {
    visibility: visible;
    opacity: 1;
}

.animateAboutOpacity {
    background: rgb(207,207,207);
    background: linear-gradient(180deg, rgba(207,207,207,0) 0%, rgba(207,207,207,0.8673844537815126) 48%, rgba(207,207,207,0.8981967787114846) 91%);  
    width: 100vw;
    height: 0vh;
    position: fixed;
    bottom: 0;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    z-index: 38;
    overflow: hidden;
}

.animateAboutOpacity.show {
    opacity: 1;
    height: 100vh;
}

.storiesContainerAnimation {
    animation: fadeInAnimation ease-in 0.5s;
}

.jonasIntroContainerAnimation {
    animation: fadeInAnimation ease-in 1s;
}

.uploadStoriesContainerAnimation {
    animation: fadeInAnimation ease-in 0.5s;
    transition: width 0.4s ease-in;
}

.pulsate {
    animation: pulsate 3s ease-in-out;
    animation-iteration-count: infinite;
    text-shadow: 0px 0px 0px #FFF;
}

.pulsateBlack {
    animation: pulsateBlack 3s ease-in-out;
    animation-iteration-count: infinite;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

@keyframes pulsate {
    0% { 
        text-shadow: 0px 0px 0px #FFF;
    }
    50% { 
        text-shadow: 0px 0px 4px #FFF;
    }
    100% { 
        text-shadow: 0px 0px 0px #FFF;
    }
}

@keyframes pulsateBlack {
    0% { 
        text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
    }
    50% { 
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    }
    100% { 
        text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
    }
}

@keyframes fadeInAnimation {
    0%, 40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOutAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* for Mobile HORIZONTAL 480px and above */
@media all and (min-width: 480px) {
    .animateOpacity.hide {
        width: 100%;
    }

    /* .buttonInactive.white {
        width: auto;
    } */
    
  }
