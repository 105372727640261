/* BUTTON TEMPLATE */
.button {
    display: flex;
    transition: all 0.1s ease-out;
    border-radius: 1.8rem;
    border: 1px solid black;
    margin: 1rem 1rem;
    padding: 0.4rem 1rem;
}

.button.active {
    background-color: black;
}

.button.inactive {
    background-color: white;
}

.button:hover {
    box-shadow: inset 0px 0px 4px 4px rgba(0,0,0,0.1);
    border-color: rgba(0,0,0,0.3);
}

.button:active {
    background-color: black;
    color: white;
}

.panelButton {
    transition: all 0.1s ease-out;
}

.panelButton:hover {
    box-shadow: inset 0px 0px 4px 4px rgba(0,0,0,0.1);
    border-color: rgba(0,0,0,0.3);
}

/* SOUNDSCAPE CURSOR */
.soundscapeCursor {
    z-index: 60;
    padding: 1.25rem 0rem;
    cursor:   url(../img/cursor-play.svg), 
              url(../img/cursor-play.png), 
              url(../img/cursor-play.jpg), pointer;
  }

  /* .soundscapeCursor.pause {
    cursor: url(../img/cursor-pause.svg), 
            url(../img/cursor-pause.png), 
            url(../img/cursor-pause.jpg), pointer;
    Safari
    -webkit-cursor: url(../img/cursor-pause.svg), 
            url(../img/cursor-pause.png), 
            url(../img/cursor-pause.jpg), pointer;
    Firefox
    -moz-cursor: url(../img/cursor-pause.svg), 
            url(../img/cursor-pause.png), 
            url(../img/cursor-pause.jpg), pointer;
    IE
    -ms-cursor: url(../img/cursor-pause.svg), 
            url(../img/cursor-pause.png), 
            url(../img/cursor-pause.jpg), pointer;
    Opera
    -o-cursor: url(../img/cursor-pause.svg), 
            url(../img/cursor-pause.png), 
            url(../img/cursor-pause.jpg), pointer;
  } */

/* SCROLLBAR HIDE */
/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollBar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .noScrollBar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  select option{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px;
}

/* MEDIA QUERIES */

/* for Mobile HORIZONTAL 480px and above */
@media all and (min-width: 480px) {    }