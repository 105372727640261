.mapboxContainerDashboard{
    transition: all 0.3s ease-in;
    height: 100vh;
    margin-left: 2.25rem;
    margin-right: 2.25rem;
}
.mapboxContainerLoadMemory{
    transition: all 0.3s ease-in;
    height: 100vh;
    margin-left: 2.25rem;
    margin-right: 50vw;
}
.mapboxContainerUploadStory{
    transition: all 0.3s ease-in;
    height: 100vh;
    margin-left: 50vw;
    margin-right: 2.25rem;
}


.landmarkMarker{
    background-color: white; 
    border-radius: 25px;
    padding: 5px;
    cursor:pointer;
    color:black;
    font-family: "Area Normal", sans-serif;
    font-size: 12px;
    box-shadow: 2px 2px 5px gray;
}

.selectedLandmark{
    background-color: black;
    color: white;
    font-family: "Apoc Revelations", serif;
    font-weight: 700;
}

.landmarkMarker:hover{
    background-color: black;
    color: white;
    font-family: "Apoc Revelations", serif;
    font-weight: 700;
    /* font-family: "FT88", monospace; */

}
.landmarkMarker:focus {
    background-color: black;
    color: white;
}

.landmarkCircle{
    height:10px; 
    width: 10px;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 10px;
    /* background-color: rgb(163, 220, 245); */
}

.landmarkText{
    margin-left: 20px;
    margin-right: 10px;
}

.hideElement{
    display:none;
}

