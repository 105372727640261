.soundscape {
    box-shadow: 10px 5px 5px red;
}

.icon {
  background-image: url(../img/cursor-play.svg);
  background-size: cover;   
  background-repeat: no-repeat;
  height: 2rem;
  width: 2rem;
  padding: 1rem;
}

.icon.pause {
  background-image: url(../img/cursor-pause.svg);
}

/* Marquee styles */
.marquee {
    --gap: 0rem;
    position: relative;
    display: flex;
    overflow-x: clip;
    /* overflow-x: hidden; */
    /* user-select: none; */
    gap: var(--gap);
  }
  
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    /* margin: 0px; */
    /* width: 10rem; */
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  
  .marquee__content {
    animation: scroll 100s linear infinite;
  }
  